import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import Typography from 'components/uiLibrary/Typography';
import SeparatorList from 'components/uiLibrary/SeparatorList';

import { getWorkTitle, getCreatorsName } from 'utils/works';
import ImpressionTracker from 'utils/components/impressionTracker';
import { getCityCountry } from 'utils/globals';
import { ENTITY_TYPE } from 'constants/index';

const useEntityInfo = ({ name, entityType, entity, asJson, props }) => {
  const Children = useMemo(() => {
    if (name) {
      return name;
    }

    switch (entityType) {
      case ENTITY_TYPE.ARTIST:
      case ENTITY_TYPE.PRODUCTION:
      case ENTITY_TYPE.MANAGER: {
        return entity?.name || '';
      }
      case ENTITY_TYPE.ORGANIZATION:
        return props?.withLocation ? compact([entity?.name, getCityCountry(entity)]).join(' | ') : entity?.name;
      case ENTITY_TYPE.WORK: {
        return getWorkTitle(entity, props?.withComposers, props?.onlyShortName, asJson);
      }
      default: {
        return '';
      }
    }
  }, [name, entityType, entity, props?.withComposers, props?.onlyShortName, props?.withLocation, asJson]);

  return Children;
};

const BaseEntityName = ({ children, name, entity, entityType, trackEntityType, trackingData }) => {
  if (isEmpty(trackingData)) {
    return children;
  }

  const organisationType = entity?.organizationType?.slug;
  return (
    <ImpressionTracker
      as="span"
      data={{
        ...trackingData,
        entityId: entity?.id,
        entityName: name || entity?.name || entity?.original_name,
        entityType: trackEntityType || entityType,
        meta: {
          ...(organisationType && { organisationType }),
          ...trackingData.meta,
        },
      }}
    >
      {children}
    </ImpressionTracker>
  );
};

const MusicalWork = ({ entityInfo, typographyProps, entity, eventType, trackingData }) => {
  const { nameProps, composerProps, translationProps } = useMemo(
    () => ({
      nameProps: {
        variant: 'span',
        size: 14,
        weight: 'bold',
        color: 'primary',
        ...(typographyProps?.work || {}),
      },
      translationProps: {
        variant: 'span',
        size: 12,
        color: 'secondary',
        ...(typographyProps?.translation || {}),
      },
      composerProps: {
        variant: 'span',
        size: 12,
        italic: true,
        color: 'secondary',
        ...(typographyProps?.composer || {}),
      },
    }),
    [typographyProps],
  );
  const composers = getCreatorsName(entity, true);
  return (
    <>
      <Typography>
        {entityInfo?.name && <Typography {...nameProps}>{entityInfo.name}</Typography>}
        {entityInfo?.translation && <Typography {...translationProps}>{`${entityInfo?.translation}`}</Typography>}
        {entityInfo?.composers?.length > 0 && (
          <Typography {...composerProps}>
            {entityInfo?.translation ? `, ` : <Typography {...nameProps}>,</Typography>}{' '}
            <SeparatorList
              data={composers?.map(composer => (
                <EntityName
                  name={composer.name}
                  entityType={composer.entityType}
                  entity={composer}
                  eventType={`${composer.entityType}${eventType?.charAt(0)?.toUpperCase() + eventType?.slice(1)}`}
                  isRaw
                  trackingData={trackingData}
                />
              ))}
            />
          </Typography>
        )}
      </Typography>
    </>
  );
};

// TODO: trackEntityType should not exist - the entity tracked should be the same as the entity type
const EntityName = ({
  name,
  entityType,
  entity,
  trackEntityType,
  eventType,
  isRaw = false,
  trackingData,
  ...props
}) => {
  const entityInfo = useEntityInfo({ name, entityType, entity, asJson: !isRaw, props });

  if (!isRaw && entityType === ENTITY_TYPE.WORK) {
    return (
      <BaseEntityName
        entityType={entityType}
        entity={entity}
        name={name}
        trackEntityType={trackEntityType}
        trackingData={trackingData}
      >
        <MusicalWork
          entityInfo={entityInfo}
          typographyProps={props?.typographyProps}
          entity={entity}
          eventType={eventType}
          trackingData={trackingData}
        />
      </BaseEntityName>
    );
  }

  if (entityInfo) {
    return (
      <BaseEntityName
        entityType={entityType}
        entity={entity}
        name={name}
        trackEntityType={trackEntityType}
        trackingData={trackingData}
      >
        {entityInfo}
      </BaseEntityName>
    );
  }

  return null;
};

export default EntityName;
